import React from "react";
// import { FormattedMessage } from "react-intl";
import { styled, makeStyles, createStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
import { ITeam, IMatch } from "../../types";
import {
  Card,
  Grid,
  Chip,
  List,
  ListItem,
  ListItemText,
  Divider,
  Theme,
} from "@material-ui/core";
import Typography from "../typography";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  flexDirection: "column-reverse",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

export const Centered = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  // padding: theme.spacing(1, 1.5, 1, 1.5)
}));

// const Title = styled(Link)(({ theme }: IStyledArguments) => ({
//   ...theme.typography.h6,
//   marginBottom: 0
// }));

// const Subtitle = styled(Typography.Subtitle1)(
//   ({ theme }: IStyledArguments) => ({
//     color: theme.palette.text.secondary
//   })
// );

// const CreatedDate = styled(Typography.Subtitle1)(() => ({
//   textAlign: "right"
// }));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  flexDirection: "row",
  paddingtop: "20px",
  justifyContent: "space-between",
});

const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
}

const getNumberOfMatchesWon = (matches: any) => {
  console.log("matches--------");
  console.log(matches);
  if (matches) {
    const numberOfWonGames = matches
      .map((match: any) => (match && match.isWon ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfWonGames);
    return numberOfWonGames;
  }
  return 0;
};

const getNumberOfMatchesTied = (matches: any) => {
  console.log("matches--------");
  console.log(matches);
  if (matches) {
    const numberOTiedMatches = matches
      .map((match: any) => (match && match.isTied ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOTiedMatches);
    return numberOTiedMatches;
  }
  return 0;
};

const getNumberOfMatchesLost = (matches: any) => {
  console.log("matches--------");
  console.log(matches);
  if (matches) {
    const numberOfLostMatches = matches
      .map((match: any) => (match && match.isLost ? 1 : 0))
      .reduce((acc: any, current: any) => acc + current, 0);
    console.log(numberOfLostMatches);
    return numberOfLostMatches;
  }
  return 0;
};

const StyledScoreValue = styled("div")(({ theme }: IStyledArguments) => ({
  fontSize: 46,
  fontWeight: 600,
  color: theme.palette.primary.main,
}));

const TeamCard = injectIntl<ITeamSummaryCardProps>(({ id, team, intl }) => {
  console.log("team=====================");
  console.log(team);

  const classesList = useStylesList();
  const matchesWon = getNumberOfMatchesWon(team.matches);
  const matchesTied = getNumberOfMatchesTied(team.matches);
  const matchesLost = getNumberOfMatchesLost(team.matches);

  return (
    <CardWrapper data-testid={`group-summary-${id}`}>
      <Header>
        <Row>
          <Grid item xs={12}>
            <Typography.H6>
              {`${intl.formatMessage(
                messages.matchesPlayed
              )}: ${(team.matches && team.matches.length) || 0}`}
            </Typography.H6>
          </Grid>
        </Row>
      </Header>
      <Content>
        {/* <Row> */}
        <Grid item xs={12}>
          <Row>
            <Grid item xs={4}>
              <Chip
                // style={{ marginBottom: "8px", width: "50%" }}
                color="primary"
                size="small"
                label={`${matchesWon} ${intl.formatMessage(messages.won)}`}
                onClick={() => null}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                // style={{ marginBottom: "8px", width: "100%" }}
                color="default"
                size="small"
                label={`${matchesTied} ${intl.formatMessage(messages.draw)}`}
                onClick={() => null}
              />
            </Grid>
            <Grid item xs={4}>
              <Chip
                // style={{ marginBottom: "8px", width: "100%" }}
                // style={{ backgroundColor: "#ff3d00", color: "white" }}
                color="secondary"
                size="small"
                label={`${matchesLost} ${intl.formatMessage(messages.lost)}`}
                onClick={() => null}
              />
            </Grid>
          </Row>
        </Grid>
        {/* </Row> */}
        {/* <Row> */}
        {/* <Grid container> */}
        <Grid item xs={12}>
          <List className={classesList.root}>
            {team.matches &&
              team.matches
                .sort(
                  (a, b) =>
                    Number(b.date.replace(new RegExp("-", "g"), "")) -
                    Number(a.date.replace(new RegExp("-", "g"), ""))
                )
                .map((match: IMatch) => (
                  <>
                    <ListItem alignItems="center">
                      <Typography.H6>{match.date}</Typography.H6>
                    </ListItem>
                    <ListItem alignItems="center">
                      {/* <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar> */}
                      {/* <Badge
                          color="secondary"
                          badgeContent={player.totalGoals}
                          className={classesList.margin}
                        >
                          <UserAvatar
                            user={{
                              name: player.name,
                              surname: player.surname
                              // number: player.number
                            }}
                            data-testid={`user-avatar-${player.id}`}
                          />
                        </Badge> */}
                      <ListItemText>
                        <Centered>
                          {match.isHomeTeamLocal && (
                            <>
                              <Typography.H6>
                                {match.homeTeam.name}
                              </Typography.H6>
                              {" vs "}
                              <Typography.Subtitle1 style={{ paddingLeft: 16 }}>
                                {match.awayTeam}
                              </Typography.Subtitle1>
                            </>
                          )}

                          {!match.isHomeTeamLocal && (
                            <>
                              <Typography.Subtitle1>
                                {match.awayTeam}
                              </Typography.Subtitle1>
                              {" vs "}
                              <Typography.H6 style={{ paddingLeft: 16 }}>
                                {match.homeTeam.name}
                              </Typography.H6>
                            </>
                          )}
                        </Centered>
                      </ListItemText>
                      {/* <ListItemText
                        primary={`${match.homeTeam.name} vs ${match.awayTeam}`}
                      /> */}
                    </ListItem>
                    <ListItem alignItems="center">
                      {match.isHomeTeamLocal &&
                        match.score.home - match.score.away > 0 && (
                          <StyledScoreValue>
                            {match.score.home} - {match.score.away}
                          </StyledScoreValue>
                        )}
                      {match.isHomeTeamLocal &&
                        match.score.home - match.score.away < 0 && (
                          <StyledScoreValue style={{ color: "#FF5722" }}>
                            {match.score.home} - {match.score.away}
                          </StyledScoreValue>
                        )}
                      {!match.isHomeTeamLocal &&
                        match.score.home - match.score.away > 0 && (
                          <StyledScoreValue>
                            {match.score.away} - {match.score.home}
                          </StyledScoreValue>
                        )}
                      {!match.isHomeTeamLocal &&
                        match.score.home - match.score.away < 0 && (
                          <StyledScoreValue style={{ color: "#FF5722" }}>
                            {match.score.away} - {match.score.home}
                          </StyledScoreValue>
                        )}
                      {/* <StyledScoreValue>{match.score.away}</StyledScoreValue> */}
                      {/* <Typography.H6>
                        11111111{`${match.score.home} - ${match.score.away}`}
                      </Typography.H6> */}
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />
                  </>
                ))}
          </List>
        </Grid>
        {/* </Grid> */}
        {/* </Row> */}
      </Content>
    </CardWrapper>
  );
});

export default TeamCard;
