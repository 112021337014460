import React from "react";
// import { FormattedMessage } from "react-intl";
import { styled, createStyles } from "@material-ui/styles";
import { IStyledArguments } from "../../types/styled-arguments";
// import Typography from "../typography";
import { ITeam, IPlayer } from "../../types";
import {
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Badge,
  makeStyles,
  Theme,
} from "@material-ui/core";
import Link from "../link";
// import messages from "./GroupSummaryCard.intl";
import UserAvatar from "../user-avatar";
import { injectIntl } from "react-intl";
import messages from "./PageViewTeam.intl";

const useStylesList = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    margin: {
      margin: theme.spacing(2),
    },
  })
);

const CardWrapper = styled(Card)(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.backgroundGrey,
  flexDirection: "column",
  //   height: "100%",
  display: "flex",
}));

const Header = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.lighterGrey,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Content = styled("div")(({ theme }: IStyledArguments) => ({
  background: theme.palette.extra.default,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(1, 1.5, 1, 3),
}));

const Title = styled(Link)(({ theme }: IStyledArguments) => ({
  ...theme.typography.h6,
  marginBottom: 0,
}));

// const Subtitle = styled(Typography.Subtitle1)(
//   ({ theme }: IStyledArguments) => ({
//     color: theme.palette.text.secondary
//   })
// );

// const CreatedDate = styled(Typography.Subtitle1)(() => ({
//   textAlign: "right"
// }));

const Row = styled((props) => <Grid container {...props} />)({
  width: "100%",
  flex: 1,
  justifyContent: "space-between",
});

interface ITeamSummaryCardProps {
  id: string;
  team: ITeam;
}

const ScorersCard = injectIntl<ITeamSummaryCardProps>(({ id, team, intl }) => {
  console.log("team=====================");
  console.log(team);
  const classesList = useStylesList();
  return (
    <CardWrapper data-testid={`group-summary-${id}`}>
      <Header>
        <Row>
          <Grid item xs={8}>
            <Title to={`/teams/${team.id}`}>
              {intl.formatMessage(messages.scorers)}:
            </Title>
          </Grid>
        </Row>
      </Header>
      <Content>
        {/* <Row> */}
        <Grid item xs={12}>
          <List className={classesList.root}>
            {team.squad &&
              team.squad
                .sort((a, b) => b.totalGoals - a.totalGoals)
                .slice(0, 3)
                .map((player: IPlayer) => (
                  <>
                    <ListItem alignItems="center">
                      {/* <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar> */}
                      <Badge
                        color="secondary"
                        badgeContent={player.totalGoals}
                        className={classesList.margin}
                      >
                        <UserAvatar
                          user={{
                            name: player.name,
                            surname: player.surname,
                            avatarUrl: player.avatarUrl,
                            // number: player.number
                          }}
                          data-testid={`user-avatar-${player.id}`}
                        />
                      </Badge>

                      <ListItemText
                        style={{ paddingLeft: 16 }}
                        primary={`${player.name} ${player.surname}`}
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                ))}
          </List>
        </Grid>
        {/* </Row> */}
      </Content>
    </CardWrapper>
  );
});

export default ScorersCard;
